import React from "react"
import NavMenu from "./NavMenu"
import Footer from "./Footer"
import { Container, Row, Col } from "react-bootstrap"

import "../assets/css/main.css"
import "bootstrap/dist/css/bootstrap.min.css"

const Layout = ({ children, pageInfo }) => {
  return (
    <>
      <div className="container-fluid px-0 main">
        <NavMenu pageInfo={pageInfo} />
        <Row className="g-0">
          <Col>
            <div className="container-fluid mt-0 ms-0 mb-5">
              <main>{children}</main>
            </div>
          </Col>
        </Row>
      </div>
      <Container fluid className="px-0">
        <Row className="g-0">
          <Col className="footer-col">
            <Footer />
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default Layout
