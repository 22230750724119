import React from "react"
import { Link } from "gatsby"
import { Container, Row, Col } from "react-bootstrap"

const Footer = () => {
  return (
    <footer className="page-footer">
      &copy; {new Date().getFullYear()} <span>&nbsp; | azszero®</span>
    </footer>
  )
}

export default Footer
