import React from "react"
import styled from "styled-components"

import Layout from "./components/Layout"
import Seo from "./components/Seo"
import { Link } from "gatsby"
import { navigate } from "gatsby"
import { Container, Row, Col, Card, Button } from "react-bootstrap"
import { StaticImage } from "gatsby-plugin-image"
import logo from "./assets/images/optimised.svg"
import rgb from "./assets/images/rgb.svg"
import topblue from "./assets/images/product-top-blue-min.png"
import bottomblue from "./assets/images/people-data.svg"
import herobot from "./assets/images/hero-bot.png"
import automation from "./assets/images/automation.svg"
import product from "./assets/images/product-1.svg"
import clock from "./assets/images/clock.svg"
import { BsFillAlarmFill } from "react-icons/bs"
import Hometopcards from "./components/cards/Hometopcards"
import Homehero from "./components/HomePage/Homehero"
import ai from "./assets/images/artificial-intelligence.svg"
import cog from "./assets/images/cog.svg"
import { GrGrow } from "react-icons/gr"
import SmartModulesBox from "./components/HomePage/SmartModulesBox"
import Consentrate from "./components/HomePage/Concentrate"
import Concentrate from "./components/HomePage/Concentrate"
import DivSeperator from "./components/HomePage/DivSeperator"
import Lightsection from "./components/HomePage/Lightsection"

export default function Home() {
  return (
    <Layout>
      <Seo title="azszero | Home" description="AZSZERO" />
      <div className="hero-top"></div>
      <div className="hero-bottom">
        <Homehero />

        <div className="section1-divider"></div>
        <Hometopcards />
      </div>
      <div className="container mt-0 mb-5">
        <SmartModulesBox />
        <DivSeperator />
        <Concentrate />
      </div>
      <Lightsection />
    </Layout>
  )
}
